export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const LOGOUT_CLEAR = "LOGOUT_CLEAR";
export const ADD_BANNER = "ADD_BANNER";
export const REMOVE_BANNER = "REMOVE_BANNER";
export const ADD_SNACKBAR = "ADD_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const ADD_SESSION_BANNER = "ADD_SESSION_BANNER";
export const REMOVE_SESSION_BANNER = "REMOVE_SESSION_BANNER";
